import React, { useState,useEffect } from 'react'
import "../styles/DashboardProfileSetting.css"
import axios from 'axios';
import {
    baseurl,
    setCookie,
    getCookie,
    getInvestmentDetails,
  } from "../utils/constants";

  function deleteCookie(cookieName) {
    document.cookie = cookieName + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
}
  
function DashboardProfileSetting() {
    const [loadingUserData, setLoadingUserData] = useState(false);
    const [userData, setUserData] = useState({});
    useEffect(() => {
        setLoadingUserData(true);
    
        async function fetchData() {
          try {
            const user = getCookie("usersession");
            const requestData = {
              requestTask: "getLoginData",
              email: user,
            };
            const result = await axios.post(baseurl, requestData);
            if (result && result.data) {
              setUserData(result.data);
              console.log("User Data is", result.data);
            }
          } catch (error) {
            // Handle errors here, e.g., log them or set an error state.
            console.error("Error fetching user data:", error);
            alert("Poor internet connection!!!");
          } finally {
            setLoadingUserData(false);
          }}
        
          fetchData();
        
        },[])    

          

    // const [otp, setOTP]=useState('')
    // const [newcode, setNewCode]=useState('')
    // const [confirmNewCode, setConfirmNewCode]=useState('')
    // function handleOTPChange(e){
    //     setOTP(e.target.value)
    // }
    // function handleNewCodeChange(e){
    //     setOTP(e.target.value)
    // }
    // function handleConfirmNewCodeChange(e){
    //     setOTP(e.target.value)
    // }
    
  return (
    <div>
    {loadingUserData ? (
       <i class="fa-solid fa-spinner fa-spin dashboardTabLoading"></i>
     ) : (
          
    <div className='DashboardProfileSetting'>
    <div className='profile-logo'>
        <div>
        <div className='logo-container'>
        <i class="fa-solid fa-user-gear"></i>
        </div>

        <div className='banking-details'>
            {/* <p className='TDes'><b>{userData.AccountNumber}</b></p>
            <p className='account-type'>Savings</p>
            <div className={userData.AccountVerification=="NOT VERIFIED"?"notverified-status":"verified-status"}>{userData.AccountVerification=="NOT VERIFIED"?"NOT VERIFIED":"VERIFIED"}</div> */}
        </div>
        </div>
    </div>

    <div className='profile-details'>
        <p className='TDes'><b>Personal Information</b></p>
        <div>
            <label className='profile-detail-label'>Full Name:</label>
            <p>{`${userData.firstName} ${userData.lastName}`}</p>
        </div>
        <div>
            <label className='profile-detail-label'>Mobile:</label>
            <p>{userData.phone}</p>
        </div>
        <div>
            <label className='profile-detail-label'>E-mail:</label>
            <p>{userData.email}</p>
        </div>
        <div>
            <label className='profile-detail-label'>Location:</label>
            <p>{userData.useraddress}</p>
            

        </div>
        <div>
            <label className='profile-detail-label'>Country:</label>
            <p>{userData.country}</p>
        </div>
    </div>

    <div><button style={{margin:"auto"}} onClick={()=>{
          deleteCookie("usersession");
          deleteCookie("userData");
          window.location.assign("/")
        }}> Log Out</button></div>

    
    {/* <div className='account-limits'>
        <p className='TDes'><b>Account Limits</b></p>
        <div>
            <label className='account-limits-label'>Sending (Per Transaction):</label>
            <p>$1,000,000</p>
        </div>
        <div>
            <label className='account-limits-label'>Receiving (Per Transaction):</label>
            <p>$500,000</p>
        </div>
        <div>
            <label className='account-limits-label'>Daily Transaction Limit:</label>
            <p>$500,000</p>
        </div>
        <div>
            <label className='account-limits-label'>Debit Card Limit:</label>
            <p>$500,000</p>
        </div>
        <div>
            <label className='account-limits-label'>Maximum Balance:</label>
            <p>Unlimited</p>
        </div>
    </div> */}

    {/* <div className='account-limits'>
        <p className='TDes'><b>KYC</b></p>
       <ul>
        <li>- Driver's License</li>
        <li>- International Passport</li>
        <li>- National I.D Card</li>
       </ul>

       <input
       type='file'
       />
    </div>

    <div className='account-limits'>
        <p className='TDes'><b>Get OTP</b></p>
      <button style={{width:'200px'}}>Place Request</button>

      <div className="form-field">
    <label htmlFor="otp">OTP*</label>
    <input type="number" id="OTP" name="OTP" value={otp} onChange={handleOTPChange} />
  </div>
      <div className="form-field">
    <label htmlFor="newcode">New-pin*</label>
    <input type="number" value={newcode} onChange={handleNewCodeChange} />
  </div>
      <div className="form-field">
    <label htmlFor="newcode">Confirm-pin*</label>
    <input type="number" value={confirmNewCode} onChange={handleConfirmNewCodeChange} />
  </div>
  
 

    </div> */}

 
</div>
       
     )}
  </div>

  )
}

export default DashboardProfileSetting