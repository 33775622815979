import React from 'react'
import Plans from '../Plans'

function PlansPage() {
  return (
    <div className="about-us-wrapper">
    <div className="about-container">
    <h1 className="sectionheader" data-aos="fade-in" data-aos-duration="2000">Explore Investment Plans at TradingQuestHub</h1>
    <p className="sectiondescription" data-aos="zoom-in" data-aos-duration="2000">
    Discover a range of investment plans tailored to meet your financial goals at TradingQuestHub. With over 15 years of experience and innovation, we offer high-quality access to financial markets through an advanced execution model.
    </p>
    </div>
    <Plans/>
    </div>
  )
}

export default PlansPage