import React from "react";

import axios from "axios";
import { useState, useEffect } from "react";
import {
  baseurl,
  setCookie,
  getCookie,
  getInvestmentDetails,
} from "../utils/constants";

import "../styles/DashboardWithdrawals.css";

export default function DashboardWithdrawals() {
  const [loadingUserData, setLoadingUserData] = useState(false);
  const [userData, setUserData] = useState({});
  const [userTransactions, setUserTransactions] = useState([]);
  const [userInvestments, setuserInvestments] = useState([]);

  const [userAccountBalance, setUserAccountBalance] = useState(0);
  const [Investments, setInvestments] = useState(0);
  const [Profits, setProfits] = useState(0);
  const [Withdrawalable, setWithdrawalable] = useState(0);
  const [requestingWithdrawal, setRequestingWithdrawal] = useState("false");

  const [paymentMethod, setPaymentMethod] = useState("");

  const [amount, setAmount] = useState(0);
  const [amountError, setAmountError] = useState("");
  const [bankName, setBankName] = useState("");
  const [bankAccount, setBankAccount] = useState("");
  const [routing, setRouting] = useState("");
  const [AccountName, setAccountName] = useState("");
  const [inputError, setInputError] = useState("");
  const [paymentMethodError, setPaymentMethodError] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [loadingWithdrwal, setLoadingWithdrawal] = useState(false);
  const [showWidrawalComplete, setShowWidrawalComplete] = useState(false);

  const handleChange = (event) => {
    let value = event.target.value;
    if (value !== -1) {
      let valuearray = value.split(",");
      console.log(valuearray);
      setSelectedOption(valuearray[0]);
      let invest = userInvestments.filter(
        (investment) => investment.investmentID == valuearray[0]
      );

      let newWithdrawable = invest[0].accumulatedEarnings + invest[0].amount;
      setWithdrawalable(newWithdrawable);
    }
  };

  useEffect(() => {
    setLoadingUserData(true);

    async function fetchData() {
      try {
        const user = getCookie("usersession");
        const requestData = {
          requestTask: "getLoginData",
          email: user,
        };
        const result = await axios.post(baseurl, requestData);
        if (result && result.data) {
          setUserData(result.data);
          console.log("User Data is", result.data);
        }
      } catch (error) {
        // Handle errors here, e.g., log them or set an error state.
        console.error("Error fetching user data:", error);
        alert("Poor internet connection!!!");
      } finally {
        const user = getCookie("usersession");

        const requestData = {
          requestTask: "getUserTransactions",
          email: user,
        };

        const result = await axios.post(baseurl, requestData);
        console.log("User transactions is", result.data);
        setUserTransactions(result.data);

        const requestData2 = {
          requestTask: "getuserinvestments",
          email: user,
        };
        const result2 = await axios.post(baseurl, requestData2);
        console.log("User investments is", result2.data);

        if (result2.data && Array.isArray(result2.data)) {
          let accountbalance = 0;
          let totalInvestments = 0;
          let totalProfits = 0;
          let WithdrawalableFunds = 0;

          let userInvestments = result2.data;
          let completeInvestmentDetails = [];
          for (let i = 0; i < userInvestments.length; i++) {
            let investment = userInvestments[i];

            let completeUserInvestment = getInvestmentDetails(investment);
            completeInvestmentDetails.push(completeUserInvestment);
            if (completeUserInvestment.status == "RUNNING") {
              accountbalance +=
                Number(completeUserInvestment.amount) +
                Number(completeUserInvestment.accumulatedEarnings);
              totalInvestments += Number(completeUserInvestment.amount);
              totalProfits += Number(
                completeUserInvestment.accumulatedEarnings
              );
            } else if (completeUserInvestment.status == "COMPLETED") {
              accountbalance +=
                Number(completeUserInvestment.amount) +
                Number(completeUserInvestment.accumulatedEarnings);
              totalInvestments += Number(completeUserInvestment.amount);
              totalProfits += Number(
                completeUserInvestment.accumulatedEarnings
              );
              WithdrawalableFunds +=
                Number(completeUserInvestment.amount) +
                Number(completeUserInvestment.accumulatedEarnings);
            } else if (completeUserInvestment.status == "BIT") {
              accountbalance += Number(
                completeUserInvestment.accumulatedEarnings
              );
              totalInvestments += 0;
              totalProfits += 0;
              WithdrawalableFunds += Number(
                completeUserInvestment.accumulatedEarnings
              );
            }
          }
          setWithdrawalable(WithdrawalableFunds);
          setProfits(totalProfits);
          setInvestments(totalInvestments);
          setuserInvestments(completeInvestmentDetails);
          setUserAccountBalance(accountbalance);
        }
        setLoadingUserData(false);
      }
    }

    fetchData();
  }, []);

  function handlePaySelect(paytype) {
    setPaymentMethod(paytype);
  }

  function verifyInputs() {
    setInputError("");
    setAmountError("");
    setPaymentMethodError("");
    if (amount == 0) {
      setAmountError("Invalid Amount");
      return false;
    } else if (amount > Withdrawalable) {
      setAmountError(
        "Insufficient balance. Max withdrawble is $" + Withdrawalable
      );
      return false;
    } else if (paymentMethod == "") {
      setPaymentMethodError("Please select payment method.");
      return false;
    } else if (paymentMethod !== "bank") {
      setPaymentMethodError("Please change payment method.");
      return false;
    } else if (bankName.trim() == "") {
      setInputError("Add bank name");
      return false;
    } else if (AccountName.trim() == "") {
      setInputError("Add bank account name");
      return false;
    } else if (routing.trim() == "") {
      setInputError("Add routing number");
      return false;
    } else if (bankAccount.trim() == "" || bankAccount.length < 5) {
      setInputError("Add bank account number");
      return false;
    } else {
      return true;
    }
  }

  async function handleWithdrawal() {
    if (verifyInputs()) {
      setLoadingWithdrawal(true);
      console.log(selectedOption);

      const user = getCookie("usersession");
      const requestData = {
        requestTask: "registerWithdrawal",
        email: user,
        amount: amount,
        firstName: userData.firstName,
        investmentID: selectedOption,
      };

      const result = await axios.post(baseurl, requestData);
      console.log(result.data);
      setShowWidrawalComplete(true);
      setLoadingWithdrawal(false);
    }
  }

  const renderPaymentMethod = (paymentMethod) => {
    switch (paymentMethod) {
      case "bank":
        return (
          <div className="payment-content">
            <h1>Bank Withdrawal</h1>

            {inputError && (
              <div className="form-error-container">
                <p className="error">
                  <i class="fa-solid fa-circle-exclamation"></i> {inputError}
                </p>
              </div>
            )}
            <input
              type="text"
              value={bankName}
              onChange={(e) => {
                setBankName(e.target.value);
              }}
              placeholder="Bank Name"
            />
            <input
              type="text"
              value={AccountName}
              onChange={(e) => {
                setAccountName(e.target.value);
              }}
              placeholder="Account Name"
            />
            <input
              type="number"
              value={bankAccount}
              onChange={(e) => {
                setBankAccount(e.target.value);
              }}
              placeholder="Account Number"
            />
            <input
              type="text"
              value={routing}
              onChange={(e) => {
                setRouting(e.target.value);
              }}
              placeholder="Routing Number"
            />
          </div>
        );
      case "paypal":
        return (
          <div className="payment-content">
            <h1>Paypal Withdrawal</h1>
            <p>This option is not available</p>
          </div>
        );
      case "crypto":
        return (
          <div className="payment-content">
            <h1>Crypto Widthdrawal</h1>

            <p>This option is not available</p>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div>
      {loadingUserData ? (
        <i class="fa-solid fa-spinner fa-spin dashboardTabLoading"></i>
      ) : (
        <div>
          {!showWidrawalComplete ? (
            <div className="DashboardWithdrawals">
              <div className="over-view-container" data-aos="slide-up">
                <h1>Withdrawal Overview</h1>
                <div className="account-overview">
                  <div  data-aos="zoom-in">
                    <p>Main Balance</p>
                    <p className="balance">${userAccountBalance.toLocaleString()}</p>
                    <p className="little-description">
                      ${Investments.toLocaleString()} invested
                    </p>
                  </div>
                  <div  data-aos="zoom-in">
                    <p>Withdrawable</p>
                    <p className="balance">${Withdrawalable.toLocaleString()}</p>
                    <p className="little-description">${Profits.toLocaleString()} profits</p>
                  </div>
                </div>
              </div>

              <div className="over-view-container"data-aos="slide-up">
                <div >
                  <h1>select Investment</h1>
                  <select value={selectedOption} onChange={handleChange}>
                    <option value={-1}>Select a plan</option>
                    {userInvestments
                      .filter((investment) => investment.status == "COMPLETED")
                      .map((item, index) => (
                        <option key={index} value={[item.investmentID, index]}>
                          {item.plan}
                        </option>
                      ))}
                  </select>
                </div>

                <div>
                  {amountError && (
                    <div className="form-error-container">
                      <p className="error">
                        <i class="fa-solid fa-circle-exclamation"></i>{" "}
                        {amountError}
                      </p>
                    </div>
                  )}
                  <h1>Amount ($)</h1>
                  <input
                    type="number"
                    value={amount}
                    onChange={(e) => {
                      setAmount(e.target.value);
                    }}
                  />
                </div>
                <h1>Method</h1>

                <div className="payment-icons">
                  <div
                    className={
                      paymentMethod == "bank" ? "selected-plan-payment" : ""
                    }
                    onClick={() => {
                      handlePaySelect("bank");
                    }}
                  >
                    <i class="fa-solid fa-building-columns"></i>
                    <p className="little-description">
                      {paymentMethod == "bank" ? "Bank" : ""}
                    </p>
                  </div>
                  <div
                    className={
                      paymentMethod == "paypal" ? "selected-plan-payment" : ""
                    }
                    onClick={() => {
                      handlePaySelect("paypal");
                    }}
                  >
                    <i class="fa-brands fa-paypal"></i>
                    <p className="little-description">
                      {paymentMethod == "paypal" ? "Paypal" : ""}
                    </p>
                  </div>
                  <div
                    className={
                      paymentMethod == "crypto" ? "selected-plan-payment" : ""
                    }
                    onClick={() => {
                      handlePaySelect("crypto");
                    }}
                  >
                    <i class="fa-brands fa-bitcoin"></i>
                    <p className="little-description">
                      {paymentMethod == "crypto" ? "Crypto" : ""}
                    </p>
                  </div>

                  {/* <div 
                className={
                  paymentMethod == "Skrill" ? "selected-plan-payment" : ""
                }
                onClick={() => {
                  handlePaySelect("Skrill");
                }}
              >
                <i class="fa-brands fa-bitcoin"></i>
                <p className="little-description">
                  {paymentMethod == "Skrill" ? "Skrill" : ""}
                </p>
              </div> */}
                </div>

                {paymentMethodError && (
                  <div className="form-error-container">
                    <p className="error">
                      <i class="fa-solid fa-circle-exclamation"></i>{" "}
                      {paymentMethodError}
                    </p>
                  </div>
                )}

                <div>{renderPaymentMethod(paymentMethod)}</div>
              </div>

              <button
                onClick={() => {
                  handleWithdrawal();
                }}
              >
                {loadingWithdrwal ? (
                  <i class="fa-solid fa-spinner fa-spin"></i>
                ) : (
                  "Widthdraw Funds"
                )}
              </button>
            </div>
          ) : (
            <div className="Investment-confirmed">
              <i class="fa-solid fa-circle-info"></i>
              <p>Request Confirmed</p>
              <p>Please contact support for to complete transaction</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
