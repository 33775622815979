import React, { useEffect } from "react";
import "../styles/DashboardHome.css";
import homebkg from "../images/desk.png";
import DashboardCard from "./DashboardHomeCard";
import {
  baseurl,
  setCookie,
  getCookie,
  getInvestmentDetails,
} from "../utils/constants";

import axios from "axios";
import { useState } from "react";

import Markets from "./Markets";
import CryptoMarket from "./CryptoMarket";
import FXPricingWidget from "./FXPricingWidget";
import PreciousMetalWidget from "./PreciousMetalWidget";
import StockMarketWidget from "./StockMarketWidget";

import { useDispatch, useSelector } from "react-redux";
function DashboardHome() {
  const loggedUserdata = useSelector((state) => state.userdata);
  const loggedUserTransactions = useSelector((state) => state.userTransactions);
  const loggedUserInvestments = useSelector((state) => state.userInvestments);

  
  const [loadingUserData, setLoadingUserData] = useState(false);
  const [userData, setUserData] = useState({});
  const [userTransactions, setUserTransactions] = useState([]);
  const [userInvestments, setuserInvestments] = useState([]);

  const [userAccountBalance, setUserAccountBalance] = useState(0);
  const [Investments, setInvestments] = useState(0);
  const [Profits, setProfits] = useState(0);
  const [Withdrawalable, setWithdrawalable] = useState(0);


   useEffect(() => {
  //   setLoadingUserData(true);

  //   async function fetchData() {
  //     try {
  //       const user = getCookie("usersession");
  //       const requestData = {
  //         requestTask: "getLoginData",
  //         email: user,
  //       };
  //       const result = await axios.post(baseurl, requestData);
  //       if (result && result.data) {
  //         setUserData(result.data);
  //         console.log("User Data is", result.data);
  //       }
  //     } catch (error) {
  //       // Handle errors here, e.g., log them or set an error state.
  //       console.error("Error fetching user data:", error);
  //       alert("Poor internet connection!!!");
  //     } finally {
  //       const user = getCookie("usersession");
  //       const requestData = {
  //         requestTask: "getUserTransactions",
  //         email: user,
  //       };
  //       const result = await axios.post(baseurl, requestData);
  //       console.log("User transactions is", result.data);
  //       setUserTransactions(result.data);

  //       const requestData2 = {
  //         requestTask: "getuserinvestments",
  //         email: user,
  //       };
  //       const result2 = await axios.post(baseurl, requestData2);
  //       console.log("User investments is", result2.data);

        // if (result2.data && Array.isArray(result2.data)) {
        //   let accountbalance = 0;
        //   let totalInvestments = 0;
        //   let totalProfits = 0;
        //   let WithdrawalableFunds = 0;

        //   let userInvestments = result2.data;
         

        //  for (let i = 0; i < userInvestments.length; i++) {
        //      let investment = userInvestments[i];
            
        //        let completeUserInvestment = getInvestmentDetails(investment);
        //                 if (completeUserInvestment.status == "RUNNING") {
        //                   accountbalance +=
        //                     Number(completeUserInvestment.amount) +
        //                     Number(completeUserInvestment.accumulatedEarnings);
        //                   totalInvestments += Number(completeUserInvestment.amount);
        //                   totalProfits += Number(
        //                     completeUserInvestment.accumulatedEarnings
        //                   );
        //                 }
        //                 else if (completeUserInvestment.status == "COMPLETED"){
        //                   accountbalance +=
        //                   Number(completeUserInvestment.amount) +
        //                   Number(completeUserInvestment.accumulatedEarnings);
        //                   totalInvestments += Number(completeUserInvestment.amount);
        //                   totalProfits += Number(
        //                     completeUserInvestment.accumulatedEarnings
        //                   );
        //                   WithdrawalableFunds +=Number(completeUserInvestment.amount)+
        //                   Number(completeUserInvestment.accumulatedEarnings);
        //                 }
        //                 else if (completeUserInvestment.status == "BIT"){
        //                   accountbalance +=
        //                   Number(completeUserInvestment.accumulatedEarnings);
        //                   totalInvestments += 0;
        //                   totalProfits += 0;
        //                   WithdrawalableFunds += Number(completeUserInvestment.accumulatedEarnings);

        //                 }
            
        //     }
        //   setWithdrawalable(WithdrawalableFunds);
        //   setProfits(totalProfits);
        //   setInvestments(totalInvestments);
        //   setuserInvestments(userInvestments);
        //   setUserAccountBalance(accountbalance);


        //   //HERE
        // }

  //       setLoadingUserData(false);
  //     }
  //   }

  //   fetchData();
   }, []); 


  useEffect(()=>{
    console.log("USER DATA",loggedUserdata)
    console.log("USER TRANSACTIONS",loggedUserTransactions)
    console.log("USER INVESTMENTS",loggedUserInvestments)

    setUserData(loggedUserdata);
    setUserTransactions(loggedUserTransactions)

    let accountbalance = 0;
          let totalInvestments = 0;
          let totalProfits = 0;
          let WithdrawalableFunds = 0;

          let userInvestments = loggedUserInvestments;
         

         for (let i = 0; i < userInvestments.length; i++) {
             let investment = userInvestments[i];
            
               let completeUserInvestment = getInvestmentDetails(investment);
                        if (completeUserInvestment.status == "RUNNING") {
                          accountbalance +=
                            Number(completeUserInvestment.amount) +
                            Number(completeUserInvestment.accumulatedEarnings);
                          totalInvestments += Number(completeUserInvestment.amount);
                          totalProfits += Number(
                            completeUserInvestment.accumulatedEarnings
                          );
                        }
                        else if (completeUserInvestment.status == "COMPLETED"){
                          accountbalance +=
                          Number(completeUserInvestment.amount) +
                          Number(completeUserInvestment.accumulatedEarnings);
                          totalInvestments += Number(completeUserInvestment.amount);
                          totalProfits += Number(
                            completeUserInvestment.accumulatedEarnings
                          );
                          WithdrawalableFunds +=Number(completeUserInvestment.amount)+
                          Number(completeUserInvestment.accumulatedEarnings);
                        }
                        else if (completeUserInvestment.status == "BIT"){
                          accountbalance +=
                          Number(completeUserInvestment.accumulatedEarnings);
                          totalInvestments += 0;
                          totalProfits += 0;
                          WithdrawalableFunds += Number(completeUserInvestment.accumulatedEarnings);

                        }
            
            }
          setWithdrawalable(WithdrawalableFunds);
          setProfits(totalProfits);
          setInvestments(totalInvestments);
          setuserInvestments(userInvestments);
          setUserAccountBalance(accountbalance);
  })
  
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://www.livecoinwatch.com/static/lcw-widget.js';
    script.defer = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div>
      {loadingUserData ? (
        <i class="fa-solid fa-spinner fa-spin dashboardTabLoading"></i>
      ) : (
        <div className="dashboard-home">
          <div className="dashboard-intro">
            <p>Welcome {userData.firstName}</p>
            <div className="cardContainer">
              <div className="carddiv"   data-aos="zoom-in">
                
                <DashboardCard 
              
                  icon={<i class="fa-solid fa-scale-unbalanced-flip"></i>}
                  Header={"Account Balance"}
                  Value={`$${userAccountBalance.toLocaleString()}`}
                />
              </div>
              <div className="carddiv"  data-aos="zoom-in">
                <DashboardCard
                  icon={<i class="fa-solid fa-clock-rotate-left"></i>}
                  Header={"Total Investments"}
                  Value={`$${Investments.toLocaleString()}`}
                />
              </div>
              <div className="carddiv" data-aos="zoom-in">
                <DashboardCard
                  icon={<i class="fa-solid fa-chart-column"></i>}
                  Header={"All-Time Profits"}
                  Value={`$${Profits.toLocaleString()}`}
                />
              </div>
              <div className="carddiv" data-aos="zoom-in">
                <DashboardCard
                  icon={<i class="fa-solid fa-money-bill-transfer"></i>}
                  Header={"Withdrawable Profits"}
                  Value={`$${Withdrawalable.toLocaleString()}`}
                />
              </div>
            </div>
          </div>

          <div className="dashboard-details">
            <div className="Markets-wrapper"  data-aos="slide-up" data-aos-delay="1500" >
              <h1 className="market-label investment-header">
                Latest Investments
              </h1>
              <div>
                {userInvestments.length == 0 ? (
                  <div>Not invested yet.</div>
                ) : (
                  <div>
                    <h1 style={{ fontWeight: "bold", fontSize: "25px" }}>
                      {userInvestments[userInvestments.length - 1].plan}
                    </h1>
                    {/* <h1>{userInvestments[userInvestments.length-1].status}</h1> */}
                    <h1>{`$${
                      userInvestments[userInvestments.length - 1].amount
                    }`}</h1>
                  </div>
                )}
              </div>
            </div>
            <div className="Markets-wrapper"  data-aos="slide-up" data-aos-delay="1500">
              <h1 className="market-label">Crypto Markets</h1>
              <div className="market-container">
                <div>
                   <CryptoMarket lcwcoin={"BTC"} lcwbase={"USD"} lcwsecondary={"BTC"}/>
                </div>
                <div>
                <CryptoMarket lcwcoin={"ETH"} lcwbase={"USD"} lcwsecondary={"ETH"}/>
                </div>
                <div>
                <CryptoMarket lcwcoin={"BNB"} lcwbase={"USD"} lcwsecondary={"BNB"}/>
                </div>
                <div>
                <CryptoMarket lcwcoin={"LTC"} lcwbase={"USD"} lcwsecondary={"LTC"}/>
                </div>
                <div>
                <CryptoMarket lcwcoin={"SOL"} lcwbase={"USD"} lcwsecondary={"SOL"}/>
                </div>
                <div>
                <CryptoMarket lcwcoin={"DOGE"} lcwbase={"USD"} lcwsecondary={"DOGE"}/>
                </div>
                <div>
                <CryptoMarket lcwcoin={"XRP"} lcwbase={"USD"} lcwsecondary={"XRP"}/>
                </div>
                <div>
                <CryptoMarket lcwcoin={"ADA"} lcwbase={"USD"} lcwsecondary={"ADA"}/>
                </div>
                <div>
                <CryptoMarket lcwcoin={"USDT"} lcwbase={"USD"} lcwsecondary={"USDT"}/>
                </div>

              </div>
            </div>

            <div className="Markets-wrapper"  data-aos="slide-up" >
              <h1 className="market-label">FX Markets</h1>
              <div className="market-container">
                <div>
                <FXPricingWidget id={1}/>
                </div>
                <div>
                <FXPricingWidget id={2}/>
                </div>
                <div>
                <FXPricingWidget id={3}/>
                </div>
                <div>
                <FXPricingWidget id={4}/>
                </div>
                <div>
                <FXPricingWidget id={5}/>
                </div>
                <div>
                <FXPricingWidget id={6}/>
                </div>
                <div>
                <FXPricingWidget id={7}/>
                </div>
                <div>
                <FXPricingWidget id={8}/>
                </div>
                <div>
                <FXPricingWidget id={9}/>
                </div>
               
               
              </div>
            </div>
            <div className="Markets-wrapper" data-aos="slide-up" >
            <h1 className="market-label">Precious Metals Markets</h1>
              <PreciousMetalWidget/>
            </div>
            <div className="Markets-wrapper">
              <h1 className="market-label">Stock</h1>
              <StockMarketWidget/>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default DashboardHome;
