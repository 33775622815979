import React from 'react'
import "../styles/CardComponent1.css"

function CardComponent1(props) {
  return (
    <div className='card-component-wrapper'  data-aos="fade-in" data-aos-duration="2000" >
        <p className='icon-wrapper'>
            {props.icon}
        </p>
        <h2>{props.header}</h2>
        <p>{props.description}</p>
    </div>
  )
}

export default CardComponent1