import React from 'react'
import "../styles/DashboardHomeCard.css"
export default function DashboardHomeCard({icon, Header, Value}) {
  return (
    <div className='DashboardHomeCard'>
      <div className='icon-holder'>
        {icon}
      </div>
      <h1>{Header}</h1>
      <p>{Value}</p>
       
 
    </div>
  )
}
