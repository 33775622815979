import React from "react";
import Hero from "../Hero";
import aboutusImage from "../../images/about-us.jpg";
import "../../styles/Home.css";
import TabComponent from "../TabComponent";
import CardComponent1 from "../CardComponent1";
import CardComponent2 from "../CardComponent2";
import CountCard from "../CountCard";
import CardComponent3 from "../CardComponent3";
import CardComponent4 from "../CardComponent4";
import customer1 from "../../images/2.png";
import customer2 from "../../images/3.png";
import customer3 from "../../images/4.png";
import customer4 from "../../images/5.jpg";

import oilrig from "../../images/oil-rig.png";
import treasure from "../../images/treasure.png";
import capital from "../../images/capital.png";
import realestate from "../../images/building.png";
import tradeImage from "../../images/multidevice.png";
import FAQs from "../FAQs";
import Plans from "../Plans";

export default function Home() {
  return (
    <div className="home-container">
      <Hero />
      <div
        className="livecoinwatch-widget-5"
        lcw-base="USD"
        lcw-color-tx="#999999"
        lcw-marquee-1="coins"
        lcw-marquee-2="movers"
        lcw-marquee-items="10"
       // data-aos="slide-right" data-aos-duration="6000" data-aos-delay="6000"
      ></div>
      <div>
      <iframe
        src="https://fxpricing.com/fx-widget/ticker-tape-widget.php?id=1,2,3,5,14,20&d_mode=compact-name"
        width="100%"
        height="85"
        style={{ border: 'unset' }}
       // data-aos="slide-left" data-aos-duration="6000" data-aos-delay="6000"
      ></iframe>
      
    </div>
      <div className="about-us-wrapper">
        <div className="about-container">
          <h1 className="sectionheader"
          data-aos="zoom-in" data-aos-duration="6000" 
          >
            Welcome to TradingQuestHub!</h1>
          <p className="sectiondescription"  data-aos="zoom-in" data-aos-duration="6000" >
            With over 15 years of excellence and innovation, TradingQuestHub
            offers high-quality access to financial markets through an advanced
            execution model. Invest in thousands of instruments across various
            asset classes.
          </p>
          <div className="investment-cards-wrapper">
            <div className="investments-card">
              <img src={oilrig} alt=""  data-aos="zoom-in" data-aos-duration="6000"  />
              <h2 className="investment-header"  data-aos="zoom-in" data-aos-duration="6000" >Oil and Gas</h2>
            </div>

            <div className="investments-card">
              <img src={treasure} alt="" data-aos="zoom-in" data-aos-duration="6000"  />
              <h2 className="investment-header" data-aos="zoom-in" data-aos-duration="6000" >Precious Metals</h2>
            </div>

            <div className="investments-card">
              <img src={capital} alt="" data-aos="zoom-in" data-aos-duration="6000"  />
              <h2 className="investment-header"  data-aos="zoom-in" data-aos-duration="6000" >Shares</h2>
            </div>
            <div className="investments-card">
              <img src={realestate} alt=""  data-aos="zoom-in" data-aos-duration="6000" />
              <h2 className="investment-header"  data-aos="zoom-in" data-aos-duration="6000" >Real Estate</h2>
            </div>
          </div>
        </div>
      </div>

      <div className="why-choose-us-container">
        <div className="why-choose-us-wrapper">
          <h1 className="sectionheader"  data-aos="zoom-in" data-aos-duration="6000" >Why Choose TradingQuestHub?</h1>
          <p className="sectiondescription"  data-aos="zoom-in" data-aos-duration="4000" >
            Discover the features that set us apart:
          </p>

          <div className="card-container">
            <div>
              <CardComponent1
                icon={
                  <i
                    class="fa-solid fa-wallet"
                    style={{ color: "rgb(182,135,86)" }}
                  ></i>
                }
                header={"Multiple Payment Options"}
                description={
                  "Enjoy flexibility with our support for various payment methods including Visa, MasterCard, bank transfer, cryptocurrency, and more."
                }
              />
            </div>
            <div>
              <CardComponent1
                icon={
                  <i
                    class="fa-solid fa-globe"
                    style={{ color: "rgb(182,135,86)" }}
                  ></i>
                }
                header={"Global Coverage"}
                description={
                  "Access our services in 80% of countries worldwide, spanning multiple continents."
                }
              />
            </div>
            <div>
              <CardComponent1
                icon={
                  <i
                    class="fa-solid fa-hand-holding-dollar"
                    style={{ color: "rgb(182,135,86)" }}
                  ></i>
                }
                header={"Low Transaction Fees"}
                description={
                  "Benefit from our incredibly low transaction fees and rates across all markets and for all customers."
                }
              />
            </div>
            <div>
              <CardComponent1
                icon={
                  <i
                    class="fa-solid fa-key"
                    style={{ color: "rgb(182,135,86)" }}
                  ></i>
                }
                header={"Secure Transactions"}
                description={
                  "Rest assured with our advanced technologies safeguarding your finances against digital thefts and hacks."
                }
              />
            </div>
            <div>
              <CardComponent1
                icon={
                  <i
                    class="fa-solid fa-user-lock"
                    style={{ color: "rgb(182,135,86)" }}
                  ></i>
                }
                header={"Robust Security"}
                description={
                  "Stay protected against DDoS attacks with full data encryption for all your transactions."
                }
              />
            </div>
            <div>
              <CardComponent1
                icon={
                  <i
                    class="fa-solid fa-headset"
                    style={{ color: "rgb(182,135,86)" }}
                  ></i>
                }
                header={"24/7 Support"}
                description={
                  "Our dedicated customer care team is available round-the-clock to address your queries and provide solutions."
                }
              />
            </div>
          </div>
        </div>
      </div>

      {/* Our Brief History */}
      <div className="our-history-container">
        <h1 className="sectionheader" data-aos="zoom-in" data-aos-duration="2000" >Our Journey</h1>
        <p className="sectiondescription" data-aos="fade-in" data-aos-duration="2000" data-aos-delay="1000">Discover our story and expertise:</p>

        <div className="our-history-wrapper">
          <div className="left">
            <h1 className="Theader" data-aos="zoom-in" data-aos-duration="2000" data-aos-delay="1500">About TradingQuestHub</h1>
            <p className="TDes" data-aos="fade-in" data-aos-duration="2000" data-aos-delay="1500">
              With over 40 years of trading experience, TradingQuestHub is
              composed of seasoned and passionate international traders from
              within the Forex industry.
            </p>
            <p className="TDes"data-aos="fade-in" data-aos-duration="2000" data-aos-delay="1800">
              Our reputation as the best Forex broker in South Africa speaks for
              itself. Our team of experienced international traders offers a
              personalized and streamlined customer experience.
            </p>
            <p className="TDes"data-aos="fade-in" data-aos-duration="2000" data-aos-delay="2000">
              With our unwavering support and guidance, our clients comfortably
              achieve their financial goals and performance targets.
            </p>
            <p className="TDes"data-aos="fade-in" data-aos-duration="2000" data-aos-delay="2200">
              In addition to quick Forex withdrawals, lifelong tradebacks, pure
              DMA access, accounts in multiple currencies, and more,
              TradingQuestHub is a reliable and safe Forex broker.
            </p>
          </div>
          <div className="right  bounce" >
            <img src={tradeImage} alt="" />

          </div>
        </div>
      </div>
      {/* Our Process is Simple and Short */}
      <div className="our-process-container">
        <div className="our-process-wraper">
          <h1 className="sectionheader">Our Simple Process</h1>

          <div className="processes-container">
            <div>
              <CardComponent3
                number={1}
                icon={<i class="fa-solid fa-user-pen"></i>}
                header={"Register"}
                description={
                  "Click on the registration button to create and verify your account."
                }
              />
            </div>
            <div>
              <CardComponent3
                number={2}
                icon={<i class="fa-solid fa-gears"></i>}
                header={"Choose an Investment Plan"}
                description={"Select from our range of investment plans."}
              />
            </div>
            <div>
              <CardComponent3
                number={3}
                icon={<i class="fa-solid fa-wallet"></i>}
                header={"Fund Your Account"}
                description={
                  "Deposit funds to access our services securely and begin transacting."
                }
              />
            </div>
            <div>
              <CardComponent3
                number={4}
                icon={<i class="fa-solid fa-money-bill-trend-up"></i>}
                header={"Perform Transactions"}
                description={
                  "Experience seamless transactions across our platform."
                }
              />
            </div>
          </div>
        </div>
      </div>

      {/* PLANS */}

      {/* <div className="plans-container">
<h1 className="sectionheader">Our Plans</h1>
  <Plans/>
</div> */}

      {/* A Summary of Our Journey */}
      <div className="our-journey">
        <div className="why-choose-us-wrapper">
          <h1 className="sectionheader" data-aos="fade-in" data-aos-duration="2000" >Our Success Story</h1>
          <p className="sectiondescription sucess-story" data-aos="zoom-in" data-aos-duration="2000">
            Over the years, TradingQuestHub has amassed extensive experience,
            bolstering our portfolio in world financial services.
          </p>

          <div className="count-cards-container">
            <div>
              <CountCard
                icon={<i class="fa-solid fa-users"></i>}
                number={62550}
                description="Active Users"
              />
            </div>
            <div>
              <CountCard
                icon={<i class="fa-solid fa-clock-rotate-left"></i>}
                number={2925}
                description="Running Days"
              />
            </div>
            <div>
              <CountCard
                icon={<i class="fa-solid fa-trophy"></i>}
                number={370}
                description="Awards Won"
              />
            </div>
            <div>
              <CountCard
                icon={<i class="fa-solid fa-earth-europe"></i>}
                number={200}
                description="Global Presence"
              />
            </div>
          </div>
        </div>
      </div>

      {/*What Our Clients Say About Us*/}
      <div className="client-reviews">
        <div className="client-reviews-wrapper">
          <h1 className="sectionheader" data-aos="fade-in" data-aos-duration="2000">Client Testimonials</h1>
          <p className="sectiondescription" data-aos="zoom-in" data-aos-duration="2000" >
            Our expertise in financial services has greatly improved the lives
            of our clients, inspiring their encouraging testimonials.
          </p>
          <div className="review-container">
            <div>
              <CardComponent4
                review={
                  "TradingQuestHub is one of the best performing microfinance companies I've experienced. Their transaction process is incredibly seamless!"
                }
                image={customer1}
                name={"Chloe Neil"}
                delay={1000}
              />
            </div>
            <div>
              <CardComponent4
                review={
                  "Great company with a great vision. Their customer service is impressive, and their transaction fees are incredibly low, which is amazing."
                }
                image={customer2}
                name={"Kim Thompson"}
                delay={1200}
              />
            </div>
            <div>
              <CardComponent4
                review={
                  "This is just awesome! One of the best services I've had. You can trust them with your funds. Their loans are really helpful and convenient too."
                }
                image={customer3}
                name={"Kyle Waters"}
                delay={1400}
              />
            </div>

            <div>
              <CardComponent4
                review={
                  "Great company with a great vision. Their customer service is impressive, and their transaction fees are incredibly low, which is amazing."
                }
                image={customer4}
                name={"Emily Anderson"}
                delay={1600}
              />
            </div>
          </div>
        </div>
      </div>

      {/* <div className="FAQs-container">

<div className="FAQ-wrapper">
                    <FAQs/>
                </div>
</div> */}

      <div className="Get-Started-container">
        <div className="get-started-wrapper">
          <div className="left">
            <div className="form-holder">
              <h1 className="Theader mini-header">Ready to Get Started?</h1>
              <form>
                <input placeholder="Enter Name *" />
                <input placeholder="Enter Email *" />
                <input placeholder="Enter Subject" />
                <textarea rows={5} placeholder="Message Body *"></textarea>

                <button className="button-div">Send Message</button>
              </form>
            </div>
          </div>
          <div className="right">
            <h1 className="Theader mini-header">
              Looking for an Easy and Secure Place to Save Your Money?
            </h1>
            <p className="TDes">
              Search no more! Click the button below to get started.
            </p>
            <button className="btn-get-solution">Get Your Solution</button>
            <hr />
          </div>
        </div>
      </div>
    </div>
  );
}
