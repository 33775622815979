import React, { useEffect, useState } from "react";
import "../styles/AdminDashboard.css";
import axios from "axios";
import { baseurl, setCookie, getCookie } from "../utils/constants";
import Modal from "./Modal";
import { json } from "react-router-dom";
function AdminDashboard() {
  const [searchValue, setSearchValue] = useState("");
  const [allusers, setAllusers] = useState([]);
  const [allInvestments, setAllInvestments] = useState([]);
  const [allTransactions, setAllTransactions] = useState([]);
  const [searchedusers, setSearchUsers] = useState([]);
  const [showsearchresults, setshowsearchresults] = useState(false);
  const [addingfunds, setAddingFunds] = useState(false);
  const [userInputs, setUserInputs] = useState([]);
  const [sendingOTP, setSendingOTP] = useState(false);
  const [verifyingAccounnt, setVerifyingAccount] = useState(false);
  const [linkingAccounnt, setLinkingAccount] = useState(false);
  const [loadingingInvestment, setLoadingInvestments]= useState(false)
  const [approvingWithdrawl, setApprovingWithdrawal]= useState(false)


  const [showModal, setShowModal] = useState(false);
  const [modalImage, setModalImage] = useState('');

  
  function fromBase64(base64, filename) {
    return new Promise((resolve, reject) => {
        try {
            // Determine the MIME type from the base64 string
            const mimeType = base64.startsWith('/9j/') ? 'image/jpeg' : 'image/png';
            
            // Convert the base64 string to a binary string
            const binaryString = atob(base64);
            
            // Create a Uint8Array from the binary string
            const len = binaryString.length;
            const bytes = new Uint8Array(len);
            for (let i = 0; i < len; i++) {
                bytes[i] = binaryString.charCodeAt(i);
            }
            
            // Create a Blob from the Uint8Array
            const blob = new Blob([bytes], { type: mimeType });

            // Adjust the filename extension based on the MIME type
            const extension = mimeType.split('/')[1];
            const fullFilename = `${filename}.${extension}`;
            
            // Create a File from the Blob
            const file = new File([blob], fullFilename, { type: mimeType });
            
            resolve(file);
        } catch (error) {
            reject(error);
        }
    });
}









    const handleOpenModal = async (imageSrc) => {
      const base64String = imageSrc;
      const filename = "Payment Screenshot";
     

      

    setModalImage(
      <img 
      src={`data:image/png;base64,${imageSrc}`} 
      alt={`Screenshot`} 
      style={{ width: '80%', height: '80%' }} 
    />
    );
        setShowModal(true);
     
      

      

  // fromBase64(base64String, filename)
  //   .then(file => {
  //       // Do something with the file object
  //       console.log(file);
  //       setModalImage(file);
  //       setShowModal(true);
  //   })
  //   .catch(error => {
  //       console.error(error);
  //   });
      
  
   
   
       
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setModalImage('');
    };
 
  const handleInputChange = (index, event) => {
    const values = [...userInputs];
    values[index] = event.target.value;
    setUserInputs(values);
  };

  useEffect(() => {
    async function getAllUsers() {
      const requestData = {
        requestTask: "getallUserData",
      };

      let Users = await axios.post(baseurl, requestData);
      if ( Users && Array.isArray(Users.data)){
        setAllusers(Users.data);

        const requestData = {
          requestTask: "getAllInvestments",
        };

        let Investments = await axios.post(baseurl, requestData);
        if (Array.isArray(Investments.data)){
          setAllInvestments(Investments.data);
          console.log(Investments.data)
          const requestData = {
            requestTask: "getAllTransactions",
          };
          let Transactions = await axios.post(baseurl, requestData);
          if (Array.isArray(Transactions.data)){
            setAllTransactions(Transactions.data);}
        }

      }
      else{
        alert("Could not retrive Data. Please check internet connection")
      }
    }

    getAllUsers();
  }, []);

  function handleSearch() {
    let searchresult = allusers.filter((user) =>
      user.email.includes(searchValue)
    );
    setSearchUsers(searchresult);
    setshowsearchresults(true);
  }
  function generateOTP() {
    var chars =
      "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    var OTP = "";
    for (var i = 0; i < 8; i++) {
      OTP += chars[Math.floor(Math.random() * chars.length)];
    }
    return OTP;
  }

  async function addFunds(email, amount, username) {
    setAddingFunds(true);
    const requestData = {
      requestTask: "addfunds",
      email: email,
      amount: amount,
      firstName: username,
    };

    let result = await axios.post(baseurl, requestData);
    console.log(result.data);
    if (result.data !== "error" && Array.isArray(result.data)) {
      setAllusers(result.data);
      setAddingFunds(false);
    } else {
      console.log(result.data);
      alert("An error occured");
      setAddingFunds(false);
    }
  }

  async function sendOTP(email, username) {
    setSendingOTP(true);
    let OTP = generateOTP();
    const requestData = {
      requestTask: "sendBillingOTP",
      email: email,
      firstName: username,
      OTP: OTP,
    };

    let result = await axios.post(baseurl, requestData);
    console.log(result.data);

    if (result.data !== "error" && Array.isArray(result.data)) {
      setAllusers(result.data);
      setSendingOTP(false);
    } else {
      console.log(result.data);
      alert("An error occured");
      setSendingOTP(false);
    }
  }

  async function verifyAccount(email, username) {
    setVerifyingAccount(true)
    const requestData = {
      requestTask: "verifyAccount",
      email: email,
      firstName: username,
    };
    let result = await axios.post(baseurl, requestData);
    console.log(result.data);
    if (result.data !== "error" && Array.isArray(result.data)) {
      setAllusers(result.data);
      setVerifyingAccount(false)
    } else {
      console.log(result.data);
      alert("An error occured");
      setVerifyingAccount(false)
    }
  }
  async function LinkAccount(email, username) {
    setLinkingAccount(true)
    const requestData = {
      requestTask: "linkAccount",
      email: email,
      firstName: username,
    };
    let result = await axios.post(baseurl, requestData);
    console.log(result.data);
    if (result.data !== "error" && Array.isArray(result.data)) {
      setAllusers(result.data);
      setLinkingAccount(false)
    } else {
      console.log(result.data);
      alert("An error occured");
      setLinkingAccount(false)
    }
  }

 async function approveinvestment(investment, user){
  setLoadingInvestments(true)
    const requestData = {
      requestTask: "approveInvestment",
      investmentID: investment.investmentID,
      investmentPlan: investment.plan,
      investmentAmount: investment.amount,
      firstName:user.firstName,
      investmentAmount:investment.amount,
      email: user.email

    };
    console.log(requestData)

    let result = await axios.post(baseurl, requestData);
    console.log(result.data)
    if (Array.isArray(requestData.data)){
      setAllInvestments(result.data[0])
      setAllTransactions (result.data[1])
      setLoadingInvestments(false)
      alert("Investment Approved Please refresh page")
    }
    else{
      console.log(result.data)
      //alert('An error occured. Please check internet connection')
      setLoadingInvestments(false)
    }
  }


  async function approveWithdrawal(transaction, user){
    setApprovingWithdrawal(true)
    const requestData = {
      requestTask: "approvewithdrawal",
      transactionID: transaction.transactionID,
      firstName:user.firstName,
      email: user.email,
      amount:transaction.amount,

    };
    console.log(requestData)
    
    let result = await axios.post(baseurl, requestData);
    console.log(result.data);
    if (Array.isArray(result.data)){
      setAllTransactions(result.data)
      setApprovingWithdrawal(false)
      alert("Withdrawal Approved Please refresh page")
    }
    else{
      console.log(result.data)
      //alert('An error occured. Please check internet connection')
      setApprovingWithdrawal(false);
    }
  }






  function UserComponent({user, index}){

    return(
      <div className="funds-container">
        <h1 style={{fontSize:"20px"}}>{user.email}</h1>
                       <details>
                        <summary>Investments</summary>
                        <div>
                          {
                          allInvestments.filter((investment)=>(investment.email == user.email)).map((investment)=>(
                            <div className="investment-card">
                              <p>Plan: {investment.plan}</p>
                              <p>Amount: {investment.amount}</p>
                             <div>
                             <p>Status: {investment.status}</p>
                             <p>Screenshot</p><button onClick={()=>{ handleOpenModal(investment.screenshot)}}>open</button>
                             </div>
                              <p>Payment Method: {investment.paymentmethod}</p>
                              {
                                investment.status=="PENDING"? (<button style={{width:"100%"}}
                                onClick={()=>{
                                      approveinvestment(investment, user)
                                }}  
                                >{loadingingInvestment?(<i class="fa-solid fa-spinner fa-spin"></i>):("Approve Investment")}</button>):("")
                              }

                              
                            </div>
                          ))
                          }
                        </div>
                       </details>

                       <details>
                        <summary>Transactions</summary>
                        <div>
                          {
                          allTransactions.filter((transaction)=>(transaction.email == user.email)).map((transaction)=>(
                            <div className="investment-card">
                              <p>TransactionType: {transaction.transactiontype}</p>
                              <p>Amount: {transaction.amount}</p>
                              <p>Date: : {transaction.Date}</p>
                              <p>Status:{transaction.status}</p>

                              {
                                transaction.transactiontype=="WITHDRAWAL"&&transaction.status=="PENDING"? (<button style={{width:"100%"}}
                                onClick={()=>{
                                      approveWithdrawal(transaction, user)
                                }}  
                                >
                                  {approvingWithdrawl?(<i class="fa-solid fa-spinner fa-spin"></i>):("Approve Withdrawal")}
                                </button>):("")
                              }

                              
                            </div>
                          ))
                          }
                        </div>
                       </details>



                        <details>
                          <summary>More Details</summary>
                          <div className="details-container">
                            <p>
                              <b>First Name: </b>
                              {user.firstName}
                            </p>
                            <p>
                              <b>Last Name: </b>
                              {user.lastName}
                            </p>
                            <p>
                              <b>Status: </b>
                              {user.AccountStatus}
                            </p>
                            
                            <p>
                              <b>Balance: </b>
                              {user.balance}
                            </p>
                           
                            <p>
                              <b>Address: </b>
                              {user.useraddress}
                            </p>
                            <p>
                              <b>Country: </b>
                              {user.country}
                            </p>

                           
                          </div>
                        </details>
                      </div>
    )

  }

  return (
    <div className="admin-dashboard">
      <div className="admin-dashboard-wrapper">
        <div className="search-div">
          <input
            type="text"
            placeholder="search-email"
            value={searchValue}
            onChange={(e) => {
              setSearchValue(e.target.value);
            }}
          />
          {showsearchresults ? (
            <i
              class="fa-solid fa-xmark search-button"
              onClick={() => {
                setSearchValue("");
                setshowsearchresults(false);
              }}
            ></i>
          ) : (
            <i
              class="fa-solid fa-magnifying-glass search-button"
              onClick={() => {
                handleSearch();
              }}
            ></i>
          )}
        </div>

        {allusers.length > 0 ? (
          <div>
            {showsearchresults ? (
              searchedusers.map((user, index) => (
                <div className="user-container">
                <div key={index}>
                <UserComponent user={user} index={index}/>
              </div>
              </div>
              ))
            ) : (
              <div className="user-container">
                {allusers.length > 0 ? (
                  allusers.map((user, index) => (
                    <div key={index}>
                      <UserComponent user={user} index={index}/>
                    </div>
                  ))
                ) : (
                  <div>Loading...</div>
                )}
              </div>
            )}
          </div>
        ) : (
          <div>Loading...</div>
        )}
      </div>
      <Modal show={showModal} onClose={handleCloseModal} imageSrc={modalImage} />
    </div>
  );
}

export default AdminDashboard;
