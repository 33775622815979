import React, { useEffect, useState } from "react";
import "../styles/DashboardInvestments.css";
import Plans from "./Plans";
import axios from "axios";
import { baseurl, setCookie, getCookie, getInvestmentDetails } from "../utils/constants";
import { useDispatch, useSelector } from "react-redux";
import { setUserTransactions } from "../redux/userTransactionSlice";
import { setUserdata, clearUserdata } from "../redux/userdataSlice";
import { setUserInvestments } from "../redux/userInvestmentSlice";

export default function DashboardInvestments() {
  const dispatch = useDispatch();
  const [loadingUserData, setLoadingUserData] = useState(false);
  const [userData, setUserData] = useState({});
  const [userInvestments, setuserInvestments] = useState([]);
  const [showInvestments, setShowInvestments] = useState(false);
 
  useEffect(() => {
    setLoadingUserData(true);

    async function fetchData() {
      try {
        const user = getCookie("usersession");
        const requestData = {
          requestTask: "getLoginData",
          email: user,
        };
        const result = await axios.post(baseurl, requestData);
        if (result && result.data) {
          setUserData(result.data);
          console.log("User Data is", result.data);
        }
      } catch (error) {
        alert("Please check your internet connection!!!");
        console.error("Error fetching user data:", error);
      } finally {
        const user = getCookie("usersession");

        const requestData = {
          requestTask: "getuserinvestments",
          email: user,
        };
        const result = await axios.post(baseurl, requestData);
        console.log("User investments is", result.data);
         dispatch(setUserInvestments(result.data))
        if (result.data && Array.isArray(result.data)) {
        
          const completeUserInvestments = [];
          if (result.data.length > 0) {
            for (let i = 0; i < result.data.length; i++) {
              let investmentDetail = getInvestmentDetails(result.data[i]);
              completeUserInvestments.push(investmentDetail);
            }

            setuserInvestments(completeUserInvestments);
            setShowInvestments(true);
          }
        } else {
          alert("Please check your internet connection!!!");
        }

        setTimeout(() => {
          setLoadingUserData(false);
        }, 1500);
      }
    }

    fetchData(); 
  }, []);

  function InvestmentCard({ investment }) {
    return (
      <div>
        {investment.status == "PENDING" ? (
          <div className="InvestmentCard" data-aos="zoom-in">
            <div className="investment-top">
              <h1>{investment.plan}</h1>
              <p>${investment.amount.toLocaleString()}</p>
            </div>
            <div>
              <p className="little-description">Status: {investment.status}</p>
              <p className="little-description">Start Date: {"Not Started"}</p>
              <p className="little-description">Time: {"0:00:00"}</p>
            </div>
            <div className="profits-container">
              <h1>Realised PNL</h1>
              <p>+{"$0"}</p>
            </div>
            <div className="not-started">
              <i className="fa-solid fa-circle-info"></i>
              <p>Please contact support to enable trading</p>
            </div>
          </div>
        ) : (
          <div className="InvestmentCard" data-aos="zoom-in">
            <div className="investment-top">
              <h1>{investment.plan}</h1>
              <p>${investment.amount.toLocaleString()}</p>
            </div>
            <div>
              <p className="little-description">Status: {investment.status}</p>
              <p className="little-description">Start Date: {investment.date}</p>
              <p className="little-description">Time: {investment.time}</p>
              <p className="little-description">Duration (days): {investment.numberOfDays}</p>
              <p className="little-description">Elapsed Days: {investment.elapsedDays}</p>
              <p className="little-description">Earnings for Today: ${investment.dailyearnings.toLocaleString()}</p>
            </div>
            <div className="profits-container">
              <h1>Realised PNL</h1>
              <p>+${investment.accumulatedEarnings.toLocaleString()} .00</p>
            </div>
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="DashboardInvestments">
      {loadingUserData ? (
        <i className="fa-solid fa-spinner fa-spin dashboardTabLoading"></i>
      ) : (
        <div>
          {showInvestments ? (
            <div className="investment-wrapper">
              <div className="investment-wrapper-header">
                <p data-aos="fade-in">Investments</p>
                <button
                  onClick={() => {
                    setShowInvestments(false);
                  }}
                  data-aos="slide-left"
                >
                  Add Investment
                </button>
              </div>
              <div className="investment-container">
                {userInvestments.map((investment, index) => (
                  <div key={index}>
                    <InvestmentCard investment={investment} />
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div className="Markets-wrapper">
              <h1 className="sectionheader" data-aos="fade-in">Select a plan</h1>
              <div className="plancard-container">
                <Plans userData={userData} />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
