

export let baseurl = "https://tradingquesthub.com/tradequesthub/engine.php"
  //export let baseurl = "http://localhost/tradequesthub/engine.php"

export function setCookie(cookieName, cookieValue, expirationMinutes) {
    var d = new Date();
    d.setTime(d.getTime() + (expirationMinutes * 60 * 1000)); // Set the expiration time in minutes
    var expires = "expires=" + d.toUTCString(); // Convert the date to UTC string
    document.cookie = cookieName + "=" + cookieValue + ";" + expires + ";path=/"; // Set the cookie with name, value, expiration, and path
}

export function checkCookie(cookieName) {
    // Split document.cookie string into individual cookies
    var cookies = document.cookie.split(';');
    
    // Iterate over each cookie
    for(var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i].trim(); // Trim any leading or trailing whitespace
        // Check if the cookie starts with the provided cookieName
        if (cookie.indexOf(cookieName + '=') === 0) {
            return true; // Cookie exists
        }
    }
    return false; // Cookie does not exist
}

export function getCookie(cookieName) {
    
    const cookiesArray = document.cookie.split(';');
  
    // Iterate over each cookie to find the one with the provided name
    for (let cookie of cookiesArray) {
      // Remove leading spaces (if any)
      cookie = cookie.trim();
  
      // Check if the cookie starts with the provided name
      if (cookie.startsWith(cookieName + '=')) {
        // Return the value of the cookie
        return cookie.substring(cookieName.length + 1);
      }
    }
  
    // Return null if the cookie doesn't exist
    return null;
  }

  function calculateDaysElapsed(dateString, timeString) {
    // Convert dateString and timeString to Date objects
    const dateTime = new Date(`${dateString}T${timeString}`);
    
    // Get the current date and time
    const currentDate = new Date();
    
    // Calculate the difference in milliseconds
    const timeDifference = currentDate - dateTime;
    
    // Convert milliseconds to days
    const daysElapsed = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    
    // Ensure the result is not negative
    return Math.max(0, daysElapsed);
}

// Example usage
const date = "2024-05-08";
const time = "02:30:08";
console.log(calculateDaysElapsed(date, time));


   export function getInvestmentDetails(investment) {
   
    console.log("Date",investment.date)
    console.log("Date",investment.time)
    const elapsedDays = calculateDaysElapsed(investment.date, investment.time);
    
    
    let dailyPercentage = 0;
    let dailyEarnings =0;
    let accumulatedEarnings = 0;
    let numberOfDays = 0;

    switch (investment.plan) {
      case "Bronze":
        dailyPercentage = 400;
        numberOfDays = 1;
        break;
      case "Silver":
        dailyPercentage = 400;
        numberOfDays = 7;

        break;
      case "Gold":
        dailyPercentage = 500;
        numberOfDays = 7;

        break;
      case "Platinum":
        dailyPercentage = 500;
        numberOfDays = 7;
        break;

      default:
        break;
    }

    
    
    if (elapsedDays<=numberOfDays){
        dailyEarnings=( dailyPercentage /100)* investment.amount
        accumulatedEarnings= elapsedDays*dailyEarnings;
       
    }
    else{
        dailyEarnings=( dailyPercentage /100)* investment.amount
        accumulatedEarnings= numberOfDays*dailyEarnings;
    }

    console.log("Accoumulated Earnings", accumulatedEarnings)
    console.log("Withdrawn",  Number(investment.Withdrawn))

    let deductAccount =true;
    let newAmount=investment.amount;
   if (accumulatedEarnings> Number(investment.Withdrawn)){
    accumulatedEarnings=accumulatedEarnings- Number(investment.Withdrawn)
   }
   else if (accumulatedEarnings< Number(investment.Withdrawn)){
   
    let overall = accumulatedEarnings+newAmount;
   console.log("Overall", overall)
   newAmount = overall- Number(investment.Withdrawn);
   accumulatedEarnings=0;
   if (newAmount<0){
    newAmount=0;
   }
   }
   

    if (investment.status=="PENDING"){
        return investment;
    }
    else {
        return {
            amount: newAmount,
            date: investment.date,
            investmentID:investment.investmentID,
            paymentmethod: investment.paymentmethod,
            plan:investment.plan,
            status: investment.status =="BIT"?"BIT":elapsedDays<numberOfDays?"RUNNING":"COMPLETED",
            time: investment.time,
            numberOfDays:numberOfDays,
            elapsedDays:elapsedDays,
            dailyearnings:dailyEarnings,
            accumulatedEarnings:accumulatedEarnings,
           
           

        }
    }
  }
  

