import React from 'react'
import "../../styles/Contact.css"
import tradeImage from "../../images/multidevice.gif"

function Contact() {
  return (
    <div>
           <div className="our-history-container">
      <h1 className="sectionheader"  data-aos="fade-in" data-aos-duration="2000">Who We Are?</h1>
       <p className="sectiondescription "  data-aos="fade-up" data-aos-duration="2000" daya-aos-delay={1000}>Discover our story and expertise:</p>

        <div className="our-history-wrapper">
          

          <div className="left"  >
            <h1 className="Theader" data-aos="fade-up" data-aos-duration="2000">About TradingQuestHub</h1>
            <p className="TDes" data-aos="fade-up" data-aos-duration="2000">
            With over 40 years of trading experience, TradingQuestHub is composed of seasoned and passionate international traders from within the Forex industry.
            </p>
            <p className="TDes" data-aos="fade-up" data-aos-duration="2000" data-aos-delay="1500">
            Our reputation as the best Forex broker in South Africa speaks for itself. Our team of experienced international traders offers a personalized and streamlined customer experience.
            </p>
            <p className="TDes"  data-aos="fade-up" data-aos-duration="2000" data-aos-delay="1700">
            With our unwavering support and guidance, our clients comfortably achieve their financial goals and performance targets. 
            </p>
            <p className="TDes"  data-aos="fade-up" data-aos-duration="2000" data-aos-delay="1900">
            In addition to quick Forex withdrawals, lifelong tradebacks, pure DMA access, accounts in multiple currencies, and more, TradingQuestHub is a reliable and safe Forex broker.
            </p>
          </div>
          <div className="right tradeimage">
          <img src={tradeImage} alt=""  data-aos="slide-left" data-aos-duration="2000" data-aos-delay="1500"/>
          </div>
        </div>
      </div>
       
    <div style={{backgroundColor:'black'}}>

    <div className="Get-Started-container">
        <h1 className='sectionheader'>Get in Touch</h1>
    <div className="get-started-wrapper">
    
                <div className="left">
                    <div className="form-holder">
                    <h1 className="Theader mini-header">Ready to get started?</h1>
                    <form>
                        <input
                        placeholder="Enter Name *"
                        />
                        <input
                        placeholder="Enter Email *"
                        />
                        <input
                        placeholder="Enter Subject"
                        />
                        <textarea rows={5} placeholder="Message Body *"></textarea>

                        <button>
                            Send Message
                        </button>
                    </form>
                    </div>
                   
                </div>
                <div className="right">
                    <h1 className="Theader mini-header">Looking for an easy and secured place to save you money?</h1>
                    <p className="TDes">Search no more! Click on the button below to get started.</p>
                    <button className="btn-get-solution">
                        Get Your Solution
                    </button>
                   <hr/>
                </div>

    </div>
</div>
    </div>


<div className='contact-cards-container'>
    <div  data-aos="slide-up" data-aos-duration="2000" data-aos-delay="1000">
    <i class="fa-solid fa-mobile-screen"></i>
   <p className='TDes'> Call Us</p>
    <p className='card-info'>+447520645543</p>
    </div>

    <div data-aos="slide-up" data-aos-duration="2000" data-aos-delay="1200">
    <i class="fa-solid fa-location-dot"></i>
   <p className='TDes'> Visit us</p>
    <p className='card-info'>TradingQuestHub.com</p>
    </div>

    <div data-aos="slide-up" data-aos-duration="2000" data-aos-delay="1400">
    <i class="fa-regular fa-envelope"></i>
   <p className='TDes'> Email us</p>
    <p className='card-info'>TradeQuestHub@gmail.com</p>
    </div>

    <div data-aos="slide-up" data-aos-duration="2000" data-aos-delay="1600">
    <i class="fa-solid fa-comments"></i>
   <p className='TDes'>Live Chat</p>
    <p className='card-info'>Chat with us 24/7</p>
    </div>
    
</div>
    </div>
  )
}

export default Contact