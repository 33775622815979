import React, { useState, useEffect } from 'react';
import '../../styles/Dashboard.css'
import DashboardHome from '../DashboardHome';
import DashboardBankTransfers from '../DashboardBankTransfers';
import DashboardCryptoTransfers from '../DashboardCryptoTransfers';
import DashboardTransactions from '../DashboardTransactions';
import DashboardLoans from '../DashboardLoans';
import DashboardProfileSetting from '../DashboardProfileSetting';
import DashboardInvestments from '../DashboardInvestments';
import { baseurl, setCookie, getCookie } from "../../utils/constants";
import DashboardWithdrawals from '../DashboardWithdrawals';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS CSS
function Dashboard() {

  useEffect(() => {
    AOS.init(); // Initialize AOS library
  }, []);
 
  const [selectedTab, setSelectedTab] = useState('Dashboard');
  const [showMenuText, setShowMenuText] = useState(true);
  const [showMobileMenu, setShowMobileMenu]=useState(false)
  const [tabLabel, setTabLabel]=useState("");
  const [userData, setUserData]= useState({});
  const [loadingUserData, setLoadingUserData] = useState(false)

  // Function to handle tab click
  const handleTabClick = (tabName) => {
    setSelectedTab(tabName);
    setTabLabel(tabName);
    if (deviceWidth<768){
        setShowMobileMenu(!showMobileMenu)
        setShowMenuText(true)
     
    }
  };

  function deleteCookie(cookieName) {
    document.cookie = cookieName + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
}

  const [deviceWidth, setDeviceWidth] = useState(window.innerWidth); 
  useEffect(() => {
      const handleResize = () => {
        setDeviceWidth(window.innerWidth);
      };
  
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    useEffect(() => {
      setLoadingUserData(true)
      // Function to check if the cookie exists
      const checkCookie = () => {
        if (document.cookie.indexOf('usersession') !== -1) {
          let uData = getCookie('userData');

          setUserData(JSON.parse(uData));
          setLoadingUserData(false)
         
        } else {
          window.location.assign("/login")
        }
      };
  
      // Initial check when component mounts
      checkCookie();
  
     
      
    }, []);


  // Function to render tab content based on selected tab
  const renderTabContent = () => {
    switch (selectedTab) {
      case 'Dashboard':
       
        return <DashboardHome/>;
      case 'Investments':
      
        return <DashboardInvestments/>;
      case 'Withdrawals':
       
        return <DashboardWithdrawals/>;
      case 'Transactions':
       
        return <DashboardTransactions/> ;
      case 'Loans':
       
        return <DashboardLoans/>;
      case 'Profile Settings':
      
        return <DashboardProfileSetting/>;
      default:
        return null;
    }
  };

  return (
    <div className='Dashboard'>
      <div className={deviceWidth>768?"DashboardMenu": showMobileMenu ? "MobileMenu":"DashboardMenu"}>
        <p>Menu</p>
        <ul>
          <li className={selectedTab === 'Dashboard' ? 'selected' : ''} onClick={() => handleTabClick('Dashboard')}>
            <i className="fa-solid fa-house"></i>
            <p>{showMenuText ? "Dashboard" : ''}</p>
          </li>
          <li className={selectedTab === 'Investments' ? 'selected' : ''} onClick={() => handleTabClick('Investments')}>
          <i class="fa-solid fa-money-bill-trend-up"></i>
            <p> {showMenuText ? "Investments" : ''}</p>
          </li>
          <li className={selectedTab === 'Withdrawals' ? 'selected' : ''} onClick={() => handleTabClick('Withdrawals')}>
          <i class="fa-regular fa-credit-card"></i>
            <p>{showMenuText ? "Widthdrawals" : ''}</p>
          </li>
          <li className={selectedTab === 'Transactions' ? 'selected' : ''} onClick={() => handleTabClick('Transactions')}>
            <i className="fa-regular fa-rectangle-list"></i>
            <p>  {showMenuText ? "Transactions" : ''}</p>
          </li>
         
          <li className={selectedTab === 'Profile Settings' ? 'selected' : ''} onClick={() => handleTabClick('Profile Settings')}>
          <i class="fa-solid fa-user-gear"></i>
            <p> {showMenuText ? "Profile Settings" : ''}</p>
          </li>
        </ul>

        <div className='logout-button' onClick={()=>{
          deleteCookie("usersession");
          deleteCookie("userData");
          window.location.assign("/")
        }} style={{fontSize:"30px", display:'flex', justifyContent:"center"}}>
        <p ></p>
        <i class="fa-solid fa-power-off"></i>
        </div> 
        
      </div>
      <div className='DashboardMenu-Tab'>
        <div className='menu-toggle' onClick={() => { deviceWidth>768?setShowMenuText(!showMenuText): setShowMobileMenu(!showMobileMenu)&& setShowMenuText(true) }}>
          <i className="fa-solid fa-bars"></i>    <p>{tabLabel}</p>
        
        </div>
     
     
        
        {
          loadingUserData ? (<i class="fa-solid fa-spinner fa-spin dashboardTabLoading"></i>): (<>{renderTabContent()}</>)
        }


        
      </div>
    </div>
  );
}

export default Dashboard;
