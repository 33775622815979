import React, { useEffect } from "react";
import MySlider from "./MySlider";
import bkg from "../images/Bkg.png";
import "../styles/Hero.css";
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS CSS

function Hero() {

    useEffect(() => {
      AOS.init(); // Initialize AOS library
    }, []);

    return (
        <div className="hero-wrapper">
            <div className="hero-container">
                <div className="hero-left" data-aos="slide-right" data-aos-duration="2000">
                    <MySlider />
                </div>
                <div className="hero-right">
                    <img
                        src={bkg}
                        alt="hero-img"
                        className="bounce"
                       
                    />
                </div>
            </div>
        </div>
    );
}

export default Hero;
